import React, { useState } from "react";
import "./ChangePassword.css";
import {
    changepassword
  } from "../../Services/Api/UserApi";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [error, setError] = useState("");

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);

    // Ellenőrizze az ékezeteket és speciális karaktereket is
    if (password.length < 6) {
      setPasswordStrength("Túl könnyű");
    } else if (/^[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/.test(password)) {
      setPasswordStrength("Könnyű");
    } else if (/\d/.test(password) && /[A-Z]/.test(password)) {
      setPasswordStrength("Közepes");
    } else if (/\d/.test(password) && /[A-Z]/.test(password) && /[^a-zA-Z0-9áéíóöőúüűÁÉÍÓÖŐÚÜŰ]/.test(password)) {
      setPasswordStrength("Erős");
    } else {
      setPasswordStrength("Megfelelő");
    }

    // Ellenőrizze, hogy egyeznek-e a jelszavak
    if (password === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirm = e.target.value;
    setConfirmPassword(confirm);

    // Ellenőrizze, hogy egyeznek-e a jelszavak
    if (newPassword === confirm) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!passwordsMatch) {
      setError("Jelszavak nem egyeznek.");
      return;
    }

    if (passwordStrength === "Too Weak" || passwordStrength === "Weak") {
      setError("Válassz nehezebb jelszót.");
      return;
    }

    // Küldés a backendre
    changepassword(newPassword, confirmPassword).then((data) => {
        if (data === "success") {
          alert("Sikeres jelszó váltás, jelentkezz be!");
          localStorage.setItem("accessToken", "");
          localStorage.setItem("isUserLoggedIn", false);
          window.location.href = "/login";
        } else {
          setError(data.message || "Something went wrong.");
        }
      })
      .catch(() => setError("Jelszóváltoztatás nem sikerült, próbáld újra."));
  };

  return (
    <div className="change-password-container">
      <h1>Jelszóváltoztatás</h1>
      <form onSubmit={handleSubmit} className="change-password-form">
        <div className="form-group">
          <label>Új jelszó</label>
          <input
            type="password"
            value={newPassword}
            onChange={handlePasswordChange}
            placeholder="Írd be az új jelszavad"
            required
          />
          {newPassword.length > 4 && (
            <div className={`password-strength ${passwordStrength.toLowerCase()}`}>
            Nehézség: {passwordStrength}
          </div>
          )}
          
        </div>
        <div className="form-group">
          <label>Új jelszó újra</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            placeholder="Írd be újra az új jelszavad"
            required
          />
          {!passwordsMatch && (
            <div className="error-message">Jelszavak nem egyeznek</div>
          )}
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit" className="submit-btn">
          Jelszó változtatása
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;