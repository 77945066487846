import { apiUrl } from '../../Components/App/Constants';
import axios from 'axios';
import { NetworkManager } from '../NetworkManager';
const apiManager = new NetworkManager(apiUrl);

export async function login(username, password) {
  try {
    const response = await axios.post(apiUrl + 'login/', {
      username: username,
      password: password,
    });
    if(response.data.status === "force_password_change"){
      localStorage.setItem('accessToken', response.data.access.access);
      window.location.href = '/password-change';
      return false;
    }
    const { access, refresh } = response.data.access;

    if (access && refresh) {
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      localStorage.setItem("isUserLoggedIn", true);
    } else {
      console.error('Tokenek nem találhatóak a válaszban.');
    }

    return response;
  } catch (error) {
    console.error('Login hiba:', error);
    throw error;
  }
}
export async function changepassword(newPassword, confirmPassword) {
  try {
    const response = await apiManager.post(apiUrl + 'change_password/', {
      new_password: newPassword,
      confirm_password: confirmPassword,
    });
    if (response.data.message === "Password changed successfully.") {
      return "success";
    } else {
      console.error('Tokenek nem találhatóak a válaszban.');
    }

    return response;
  } catch (error) {
    console.error('Login hiba:', error);
    throw error;
  }
}