import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { apiUrl } from '../App/Constants';
import {
  getEveryPromoter,
} from "../../Services/Api/PromoterApi";
import {
  getItemsFromStorage
} from "../../Services/Api/StorageApi";

export default function Intern() {
  const isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const tableStyles = {
        display: "flex",
        flexDirection: "column",
      };
      const bonusinput = {
        borderRadius: 10,
        border: "1px solid black",
      };
      
      const rowStylesOrange = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#fdcb6e"
      };
      const rowStylesGrey = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "grey"
      };
      const rowStylesRed = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#ff7675"
      };
      const rowStylesGreen = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#00b894",
        color: "white"
      };
      
      const columnStyles = {
        flexBasis: "10%",
      };
      const headerStyles = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        fontWeight: 'bold',
        padding: '10px',
        color: 'black',
        textAlign: 'center',
        fontSize: '14px'
      };
      
  const [allData, setAllData] = useState([])
  const [promoters, setAllPromoters] = useState([])
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(`2024-${currentMonth.toString().padStart(2, '0')}`)
  const isCurrentMonthSelected = selectedMonth === `2024-${currentMonth.toString().padStart(2, '0')}`;
  const currentDateMonth = new Date("2024-03");
const inputDate = new Date(selectedMonth);

  useEffect(() => {
    fetchData(selectedMonth);
    fetchPromoters();
}, []);
// Function to get all unique dates for a user
function getUniqueDates(dataArray) {
    const dateSet = new Set(dataArray.map(item => item.time.split(" ")[0]));
    return Array.from(dateSet);
  }
  // Generate a list of months from January 2023 to the current month
  const monthsList = [];
  for (let year = 2023; year <= currentYear; year++) {
    const maxMonth = year === currentYear ? currentMonth : 12;
    for (let month = 1; month <= maxMonth; month++) {
      const monthValue = month.toString().padStart(2, '0');
      monthsList.push(`${year}-${monthValue}`);
    }
  }

// Function to group objects by a given key
function groupBy(array, key) {
    return array.items.reduce((result, obj) => {
      const keyValue = obj[key];
      (result[keyValue] = result[keyValue] || []).push(obj);
      return result;
    }, {});
  }
  const fetchPromoters = () => {
    getEveryPromoter().then((response) => {
      setAllPromoters(response.filter(item => item.username !== "Super Slimmer" && item.username !== "Slim Store" && item.username !== "László Emma" && item.username !== "Zentai Zsófi").filter(item => item.jobrole == "intern").filter(item => item.isActive == 1))
    })
}
const fetchData = () => {


  const today = new Date();
  const formattedToday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

  // Három hónappal ezelőtti dátum létrehozása
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  const formattedThreeMonthsAgo = `${threeMonthsAgo.getFullYear()}-${(threeMonthsAgo.getMonth() + 1).toString().padStart(2, '0')}-${threeMonthsAgo.getDate().toString().padStart(2, '0')}`;
  getItemsFromStorage(formattedThreeMonthsAgo, formattedToday).then((data) => {
    if(data.items !== undefined) {
      const groupedArray = groupBy(data, "userName");
      // Convert the grouped object into an array of objects
      const newArray = Object.entries(groupedArray).map(([key, value]) => ({ [key]: value }));

      setAllData(newArray);
    }
  });
   
    
    
  }
  function calculateTotalValue(userData) {
    return userData.reduce((acc, item) => acc + parseInt(item.price), 0);
  }
// Function to group data by unique dates and calculate sums for each promoter
function groupDataByUniqueDates(sortedAllData) {
  const groupedData = {};

  sortedAllData.forEach((data) => {
    const { username, userData } = data;
    const userGroup = {};

    userData.forEach((item) => {
      const date = item.time.split('T')[0]; // Extract the date from the "time" field
      const totalValue = calculateTotalValue([item]);

      if (userGroup[date]) {
        userGroup[date].totalValue += totalValue;
      } else {
        userGroup[date] = { date, username, totalValue };
      }
    });

    Object.values(userGroup).forEach((userItem) => {
      if (groupedData[userItem.username]) {
        groupedData[userItem.username].push(userItem);
      } else {
        groupedData[userItem.username] = [userItem];
      }
    });
  });

  return groupedData;
}

  // Function to create day-based summed array
function createSummedArray(dataArray) {
  const summedArray = [];

  dataArray.forEach((item) => {
    const date = item.time.split('T')[0]; // Extract the date from the "time" field
    const price = parseInt(item.price);

    const existingEntry = summedArray.find((entry) => entry.date === date);
    if (existingEntry) {
      existingEntry.sum += price;
    } else {
      summedArray.push({ date, sum: price });
    }
  });

  return summedArray;
}
  // Calculate the totalValue/7000 and sort the allData array
const sortedAllData = allData.map(data => {
  var value = 6000;
  if (selectedMonth < "2023-07") {
    value = 6000
  } else {
    value = 7000
  }
  if (selectedMonth > "2023-10") {
    value = 8000
  }
  if (selectedMonth == "2023-07") {
    value = 6300
  }
  if (selectedMonth == "2023-10") {
    value = 7400
  }
  if (selectedMonth > "2023-11") {
    value = 10000
  }
  if (selectedMonth > "2024-05") {
    value = 12000
  }
  if (selectedMonth > "2024-10") {
    value = 14000
  }
    const username = Object.keys(data)[0];
    const userData = data[username];
    const dateSums = createSummedArray(userData);

    var totalize = 0.00;
    dateSums.forEach((item) => {
      if(item.date < '2023-07-25'){

        totalize += item.sum/6000
      }
      if(item.date < '2023-10-10'){

        totalize += item.sum/7000
      } 
      
      if (item.date < '2023-12-01'){
        totalize += item.sum/8000
      } else {
        if (item.date < '2024-06-01'){
          totalize += item.sum/10000
        } else if (item.date > '2024-11-17'){ 
          totalize += item.sum/14000
        } else {
          totalize += item.sum/12000
        }
      }
    })
    const totalValueNormalized = Number.isInteger(totalize) ? totalize : totalize.toFixed(1);
    const totalValueNormalizedByDates =  Number.isInteger(totalize / dateSums.length) ? totalize / dateSums.length : (totalize / dateSums.length).toFixed(1) ;
  
    return { username, userData, totalValueNormalized, totalValueNormalizedByDates, dateSums };
  }).sort((a, b) => b.totalValueNormalized - a.totalValueNormalized); // Sort in descending order

  const groupedData = groupDataByUniqueDates(sortedAllData);

const formatPrice = (value) => {
  // Convert the plain number to a numeric value
  const numericValue = parseFloat(value);
  // Check if the numeric value is a valid number
  if (!isNaN(numericValue)) {
    // Use toLocaleString to format the number with spaces as thousand separators
    return numericValue.toLocaleString('hu');
  } else {
    // Return the original value if it's not a valid number
    return value;
  }
};
function fizetes (groupedData) {
  var value = 0;
  value = 20000 * groupedData.length
  return value
  }

  const getJobName = (jobrole, foundUser, groupedData, totalValueNormalized) => {
    if (groupedData != null) {
    }
    switch (jobrole) {
      case "fulltime":
        return "Teljes munkaidős"
      case "parttime":
        return "Részmunkaidős"
      case "intern":
        return "Betanuló" + " - " + internPhase(foundUser, groupedData, totalValueNormalized) + ". fázis"
      default:
        return "Inaktív"
    }
  }
  const internPhase = (foundUser, groupedData, totalValueNormalized) => {
    if(foundUser == null || groupedData == null){
      return;
    } else {
    if (groupedData.length < 10 && totalValueNormalized < 100) {
    return 1;
  } else if (totalValueNormalized > 100 && totalValueNormalized < 150) {
    return 2;
  } else {
    return 3;
  }
}
  }
  function getColorByJob(userData, totalValueNormalized, groupedData) {
    if(userData == null || groupedData == null){
      return;
    } else {
    if (groupedData.length < 10 && totalValueNormalized < 100) {
    return rowStylesRed;
  } else if (totalValueNormalized > 100 && totalValueNormalized < 150) {
    return rowStylesOrange;
  } else {
    return rowStylesGreen;
  }}
}
  const renderPopupforDays = (alldataofValue) => {
    if(alldataofValue[0].sum != null){
      return (
        <div>
        {alldataofValue.map((day) => (
          <div className='row'>
          <div className='column leftcolumn'>
          {day["date"]}
          </div>
          <div className='column'>
            {Number.isInteger(day["sum"]/(day.date < '2023-07-25' ? 6000 : day.date < '2023-10-10' ? 7000 : day.date < '2023-12-01' ? 8000 : day.date < '2024-06-01' ? 10000 : day.date < '2024-11-18' ? 12000 : 14000)) ? day["sum"]/(day.date < '2023-07-25' ? 6000 : day.date < '2023-10-10' ? 7000 : day.date < '2023-12-01' ? 8000 : day.date < '2024-06-01' ? 10000 : day.date < '2024-11-18' ? 12000 : 14000) : day["sum"]/(day.date < '2023-07-25' ? 6000 : day.date < '2023-10-10' ? 7000 : day.date < '2023-12-01' ? 8000 : day.date < '2024-06-01' ? 10000 : day.date < '2024-11-18' ? 12000 : 14000).toFixed(1)}
          </div>
          </div>
        ))}
        </div>
      );
    } else {
      return (
        <div>
        {alldataofValue.map((day) => (
          <div className='row'>
          <div className='column leftcolumn'>
          {day["date"]}
          </div>
          <div className='column'>
          </div>
          </div>
        ))}
        </div>
      );
    }
      
    
    
  };
  var decoded = jwt_decode(localStorage.getItem("accessToken"));
  return(
    // Rendering the sorted table-like layout
<div id='masters'>
  <h2>Betanulók</h2>
      
  <div style={tableStyles}>
  <div style={headerStyles} className='onlyonPC'>
    <div></div>
    <div>Név</div>
    <div>Összes</div>
    <div>Átlag/nap</div>
    <div>Fizetés</div>
  </div>
    {sortedAllData.map((data, index) => {
      const { username, userData, totalValueNormalized, totalValueNormalizedByDates, dateSums } = data;
      const foundUser = promoters.find(obj => obj.username === username.replace(/^\s+|\s+$/g, ''));
      var profilePic = "https://leltar.slimstore.hu/profile_images/placeholder.png";
      if(foundUser != null && foundUser.profile_pic != ""){
        profilePic = "https://leltar.slimstore.hu/profile_images/" + foundUser.profile_pic;
      }
      if (username !== "Webshop" && username !== "Slim Store" && username !== "László Emma" && username !== "Zentai Zsófi" && foundUser != undefined && foundUser.jobrole === "intern") {
        var jobrole = "";
        if (foundUser!= null && foundUser.jobrole!= null && foundUser.jobrole!= "" ) {
          jobrole = foundUser.jobrole
        }
        return (
          <div key={index} style={getColorByJob(foundUser, totalValueNormalized, groupedData[username])}>
            <a href={foundUser ? `/guestprofile/${foundUser.id}` : '#'}>
            <div>
              {foundUser ? <img className='profilepicinMasters' src={profilePic} alt="Profile Picture" /> : <img className='profilepicinMasters' src={profilePic} alt="Profile Picture" />}
            </div>
          </a>
            <div style={columnStyles}>{username}  {sortedAllData[0] == data ? !isCurrentMonthSelected ? '👑' : '': ''} <br></br>({getJobName(jobrole, foundUser, groupedData[username], totalValueNormalized)})</div>
      
            <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Összesen: </p>{totalValueNormalized} db<Popup trigger={<button className='infobutton morebutton'>
            <FontAwesomeIcon className='faiconinfo' icon={faInfoCircle} /> Részletes
            </button>} modal>
        <div>{renderPopupforDays((dateSums))}</div>
        </Popup></div>
        <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Átlag/nap: </p>{totalValueNormalizedByDates} db</div>
            <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Fizetés: </p>
              {Date(inputDate) > Date(currentDateMonth) ? (jobrole != "intern" ? "net. " : "net. ") : "net. " + formatPrice(fizetes((groupedData[username])) ) + " Ft"}
              <Popup trigger={inputDate < currentDateMonth ? <button className='infobutton morebutton'>
            <FontAwesomeIcon className='faiconinfo' icon={faInfoCircle} /> Részletes
            </button> : <b></b>} modal>
        <div>{renderPopupforDays((groupedData[username]))}</div>
        <hr className='hrc onlyonMobile'/>
        </Popup>
            </div>
            <hr className='hrc onlyonMobile'/>
            
          </div>
        );
      }
      
    })}
  </div>
</div>
  )

}


