import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);


//QR KÓD KEZDETE//
// API lekérdezés a qr kódokhoz
export async function getQrCodesDetails(qrcode) {
  try {
    const response = await apiManager.get('/cardQR/?qrstring=' + qrcode);
    if (response.status === 200) {

        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

// API hívás a qr kódokhoz
export async function patchQRcode(data) {
    try {
      const response = await apiManager.patch('/cardQR/?qrstring=' + data);
      if (response.status === 201) {
          return response.data;
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }


  //Felvitel a Replace táblába
  export async function postItemToQR(json, onSuccess) {
    try {
      const response = await apiManager.post('/cardvoucher/', json);
      if (response.status === 201) {
          onSuccess();
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }