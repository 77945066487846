  import { NetworkManager } from '../NetworkManager';
  import { apiUrl } from '../../Components/App/Constants';
  import jwtDecode from "jwt-decode";
  
  const apiManager = new NetworkManager(apiUrl);
  //Statisztika legkérdezése a Storage táblából
  export async function getStatisticsByDate(fromDate, toDate, location) {
    try {
    const token = localStorage.getItem('accessToken');
    
    const decodedToken = jwtDecode(token);
      var url = '/statistics/?start_date=' + fromDate + '&end_date=' + toDate + '&location=' + location;
      if (decodedToken.priv === 2) {
        url = url + '&user=' + decodedToken.id;
      }
      const response = await apiManager.get(url);
      if (response.status === 200) {
          return response.data;
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }